<template>
  <div class="top-names top-names--state">
    <tz-banner
      class="tz-banner--center-bottom tz-banner--no-image"
      :title="pageTitle(selectedState)"
      :has-overlay="true"
      :is-light="false"
      :isPageTitle="true"
    />
    <div class="top-names__content">
      <tz-breadcrumbs
        :items="breadcrumbs"
        :site-url="siteUrl"
        :has-itemscope="true"
      />
      <div class="top-names__tables">
        <wl-names-table
          rank="Rank"
          name="Name"
          :key="index"
          v-for="(column, index) in splitArray({ array: people })"
        >
          <wl-names-row
            v-for="(item, index) in column"
            :key="index"
            :rank="item.rank.toString()"
            :name="displayNameOnTable({ person: item })"
            :url="getNameLink(item)"
          />
        </wl-names-table>
      </div>
      <nav class="top-names__nav">
        <button
          class="top-names__dropdown"
          :class="{ 'top-names__dropdown--open': isDropdownOpen }"
          v-for="item in renderIfReturnArray({ condition: isMobile })"
          :key="item"
          v-text="stateName"
          v-touch:tap="handleDropdown"
        />
        <h2
          class="top-names__nav-title"
          v-for="item in renderIfReturnArray({ condition: !isMobile })"
          :key="item"
          v-text="cms.page.state.sidebar_title"
        />
        <ul
          class="top-names__state-list"
          :class="{ 'top-names__state-list--show': isDropdownOpen }"
        >
          <li class="top-names__state" v-for="state in states">
            <a
              :href="`/top-rated-full-names/${state.value.toLowerCase()}/`"
              class="top-names__state-link"
              :class="{
                'top-names__state-link--active': state.value === selectedState
              }"
              v-text="state.name"
            />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
// Components
import TzBanner from '@trazi/tz-banner/src/tz-banner.vue';
import WlNamesTable from '@/components/shared/wl-names-table';
import WlNamesRow from '@/components/shared/wl-names-row';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import TzBreadcrumbs from '@trazi/tz-breadcrumbs/src/tz-breadcrumbs.vue';
// Helpers
const NBars = require('nbars/commonjs.js');
import breadcrumbsHelper from '@/assets/js/breadcrumbsHelper';
import seoSDK from '@/assets/js/shared/services/seo-sdk';
import splitArray from '@/assets/js/shared/helpers/splitArray';
import displayNameOnTable from '@/assets/js/shared/helpers/displayNameOnTable';
import renderIfReturnArray from '@/assets/js/shared/helpers/renderIfReturnArray';
import getMobileQuery from '@/assets/js/shared/helpers/getMobileQuery';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import getSeoRootOrStateLink from '@/assets/js/helpers/getSeoRootOrStateLink';
import getStatesList from '@/assets/js/shared/helpers/getStatesList';

const { Top1000Fullnames } = seoSDK;
const mobileMediaQuery = getMobileQuery({ width: 1170 });
// CMS
const pageCms = require(`@/assets/cms/pages/top-full-names.json`);
const states = getStatesList({ hasTerritories: true });
const stateList = [];

Object.entries(states).forEach(([key, val]) => {
  stateList.push({
    name: val,
    value: key
  });
});

export default {
  name: 'most-popular-full-names-state',
  components: { TzBanner, WlNamesRow, WlNamesTable, TzMarkdown, TzBreadcrumbs },
  data() {
    return {
      selectedState: this.$route.params.state.toUpperCase(),
      states: stateList,
      isMobile: false,
      isDropdownOpen: false,
      Top1000Fullnames,
      splitArray,
      displayNameOnTable,
      renderIfReturnArray,
      getSeoRootOrStateLink
    };
  },
  computed: {
    breadcrumbs() {
      return breadcrumbsHelper.getTopFullNamesBreadcrumbs(
        this.getStateName(this.selectedState)
      );
    },
    siteUrl() {
      return `https://information.com`;
    }
  },
  created() {
    this.stateName = this.getStateName(this.selectedState);
  },
  methods: {
    /**
     * Returns the whole name of the state. e.g. FL -> Florida
     *
     * @param {string} currentState
     * @return {string}
     */
    getStateName(currentState) {
      return this.states
        .map(state => state)
        .find(state => state.value === currentState).name;
    },
    /**
     * Adds the state's name on the title
     *
     * @param {string} currentState
     * @returns {any}
     */
    pageTitle(currentState) {
      return NBars.transform(this.cms.page.state.page_title, {
        state: this.getStateName(currentState)
      });
    },
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    handleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getNameLink(item) {
      const state = getStateFromAbbr({
        stateAbbr: this.$route.params.state.toUpperCase(),
        includeTerritories: true
      })?.replace(/ /g, '-');
      return getSeoRootOrStateLink({ person: item, location: state });
    }
  },
  mounted() {
    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms
        };
      }
    }
  },
  async asyncData({ $bugsnag, params, redirect }) {
    if (!process.server) {
      return;
    }

    const { state } = params;
    const payload = {};

    try {
      payload.people = await Top1000Fullnames.byState(state);
      if (!Array.isArray(payload.people)) {
        throw Error('people is not an array');
      }
    } catch (err) {
      payload.people = [];
      console.error(err);
      $bugsnag?.notify(err);
    }

    // update results to add in rank number. e.g 1-1000
    payload.people = payload.people.map((people, index) => ({
      ...people,
      rank: index + 1
    }));

    // redirect to the top full names page if results are empty
    if (payload.people.length === 0) {
      redirect('/top-rated-full-names/');
    }

    return payload;
  },
  head() {
    const fullState = getStateFromAbbr({
      stateAbbr: this.selectedState,
      includeTerritories: true
    });
    const title = NBars.transform(this.cms.page.state.meta.page_title, {
      state: fullState
    });
    const transformedMeta = [];

    for (let i = 0; i < this.cms.page.state.meta.list.length; i++) {
      const metaCopy = Object.assign({}, this.cms.page.state.meta.list[i]);
      const { hid, content } = metaCopy;

      switch (hid) {
        case 'og:title':
        case 'apple-mobile-web-app-title':
          metaCopy.content = NBars.transform(content, {
            state: fullState
          });
          break;
      }
      transformedMeta[i] = metaCopy;
    }

    return {
      title: title,
      meta: transformedMeta
    };
  }
};
</script>
