import getStateFromAbbr from '../shared/helpers/getStateFromAbbr';
import formatNameCaseWithSplitterAndSpacer from '../shared/helpers/formatNameCaseWithSplitterAndSpacer';
/**
 * Links person to the root page
 *  If they have a state, link the state page
 * @param {object} opts
 * @param {object} opts.person
 * @param {string} opts.location
 * @param {*} opts.defaultValue
 * @returns {string}
 */
const getSeoRootOrStateLink = opts => {
  if (!opts || !opts.person) {
    return opts?.defaultValue ?? '';
  }
  const { firstname, lastname, state } = opts.person;
  const firstNameParam = formatNameCaseWithSplitterAndSpacer({
    name: firstname,
    spacer: '_',
    splitter: ' '
  });
  const lastNameParam = formatNameCaseWithSplitterAndSpacer({
    name: lastname,
    spacer: '_',
    splitter: ' '
  });

  const place =
    opts.location ??
    getStateFromAbbr({ stateAbbr: state, includeTerritories: true })?.replace(
      / /g,
      '-'
    );

  if (!place) {
    return `/people/${firstNameParam}-${lastNameParam}/`.toLowerCase();
  }

  return `/people/${firstNameParam}-${lastNameParam}/${place}/`.toLowerCase();
};

export default getSeoRootOrStateLink;
